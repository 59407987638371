.seccion-datos {
    width: 894.31px;
    height: auto;
    margin: auto auto;
    border-bottom: 1px solid rgb(230, 236, 240);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.subseccion-datos {
    display: flex;
    width: 100%;
}

.contenedor-mitad-datos {
    width: 447.15px;
}

.subtitulo-datos {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.contenedor-input-datos {
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
}

.seccion-input {
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.nombre-dato {
    width: auto;
    top: 0px;
    margin-right: 10px;
}

.contenedor-corto {
    width: 80px;
}

.contenedor-normal {
    width: 230px;
}

.contenedor-largo {
    width: 400px;
}

.input-text-datos {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    padding: 3px 10px 3px 10px;
    letter-spacing: normal;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    background-color: white;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.input-text-datos:focus {
    outline: none;
    background-color: white;
    border-bottom: 2px solid #1a73e8be;
    transition: 'all 400ms ease-in-out';
}

.input-text-datos:hover {
    transition-duration: .2s, .5s;
    transition-timing-function: linear, ease-in-out;
    -webkit-box-shadow: 0px 5px 20px -11px rgba(1158, 158, 158);
    -moz-box-shadow: 0px 5px 20px -11px rgb(158, 158, 158);
    box-shadow: 0px 5px 20px -11px rgba(158, 158, 158);
}

.mensaje-errror {
    color: red;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.seccion-guardar {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.boton-guardar {
    width: 400px;
    margin: auto;
    border: 2px solid #20b80cbe;
    outline: none;
    color: #20b80cbe;
    background-color: rgba(255, 255, 255, 0.747);
    padding: 10px 20px;
    cursor: pointer;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    font-size: 19px;
    font-weight: bold;
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  
  .boton-guardar:hover {
    transition-duration: .1s, .5s;
    transition-timing-function: linear, ease-in-out;
    background-color: #20b80cbe;
    color: white;
  }

  .hover-general {
    width: 100%;
    height: 100%;
    background-color: rgba(245,248,250);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
