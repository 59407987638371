.header-colegiaturas {
    height: 150px;
    /* background-color: #ffdd579c; */
    background: rgb(255,221,87);
    background: linear-gradient(43deg, rgba(255,221,87,0.7094188017003676) 0%, rgba(252,210,44,0.9251050762101716) 96%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: 10px solid #ffe346;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.header-colegiaturas:hover {
    background-color: #ffe346;
}

.titulo-colegiaturas {
    width: auto;
    margin-top: 0px;
    font-size: 20px;
    color: rgba(49, 49, 49, 0.884);
}

.titulo-colegiaturas h2 {
    margin-top: 0em;
    margin-bottom: 0em;
}