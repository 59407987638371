.header-datos {
    height: 150px;
    background: rgb(112, 25, 252);
    background: linear-gradient(204deg, rgba(112, 25, 252,1) 0%, rgba(112, 25, 252, 0.671) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-left: 10px solid rgb(112, 25, 252);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.header-datos:hover {
    background-color: rgb(112, 25, 252);
}

.titulo-datos {
    width: auto;
    margin-top: 0px;
    font-size: 20px;
    color: rgba(49, 49, 49, 0.884);
}