.contenedor-menu {
  position: relative;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contenedor-opc {
  display: inline-block;
  width: auto;
  align-items: center;
  margin-bottom: 3px;
  cursor: pointer;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border-bottom-left-radius: 9999px;
  border-bottom-right-radius: 9999px;
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.opc {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  display: block;
  font-size: 19px;
  font-weight: bold;
  cursor: pointer;
  color: rgba(128, 128, 128, 0.767);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  text-decoration:none;
  text-align: inherit;
}

.contenedor-opc:hover {
transition-duration: .1s, .1s;
transition-timing-function: linear, ease-in-out;
background-color: rgba(29, 161, 242, 0.1);
}

.opc:hover {
color: rgba(29,161,242,1.00);
}

.activo .contenedor-opc .opc {
  color: rgba(29,161,242,1.00);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}