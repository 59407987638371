.header-bienvenida {
    height: 350px;
    background-image: url(../../../images/libros-02.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.header-bienvenida:hover .titulo-inicio {
    background-color: rgba(224, 224, 8, 0.897);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.05);
    transform: scale(1.01);
}

.contenedor-texto {
    width: auto;
    height: auto;
    margin: auto;
}

.titulo-inicio {
    background-color: rgba(196, 196, 10, 0.758);
    width: 800px;
    height: 55px;
    margin-bottom: 10px;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(49, 49, 49, 0.884);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.subtitulo-inicio {
    background-color: rgba(29, 160, 242, 0.758);
    width: 400px;
    height: 35px;
    margin: auto;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(240, 240, 240, 0.911);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.subtitulo-inicio:hover {
    background-color: rgba(29, 160, 242, 0.897);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.03);
    transform: scale(1.01);
}

.subtitulo-inicio p {
    margin-top: 0em;
    margin-bottom: 0em;
}

.h2-titulo {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin: auto;
}