.seccion-inscripcion {
    width: 100%;
    min-height: 150px;
    margin: auto auto;
}

.contenedor-pago {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top: 1px solid rgb(230, 236, 240);
    border-bottom: 1px solid rgb(230, 236, 240);
}